<template>
  <div class="page-accueil">
    <div class="container">
      <div class="presentation card">
        <h1>{{ $t('home.title') }}</h1>

        <div v-if="!isAuthenticated">
          <i18n path="home.need_login" tag="p">
            <router-link exact :to="{name: 'login'}" place="link">
              {{ $t('home.link_connect') }}
            </router-link>
          </i18n>
        </div>
        <div v-if="isAuthenticated && currentUser">
          {{ $t('home.profile_info', { username: currentUser.nom } ) }}
        </div>
        <p v-if="isValidated" class="warning">
          {{ $t('home.is_validated') }}
        </p>
        <p v-else>
          <router-link class="nav-link" active-class="active" :to="{ name: 'expos' }">
            <i class="ion-compose"></i>{{ $t('home.link_to_shows')}}
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT, PING } from '../../store/const/actions.type';

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated', 'isValidated', 'currentExpo']),
  },
  mounted() {
    const networkAvailable = navigator.onLine;
    if (this.isAuthenticated && networkAvailable) {
      this.$store.dispatch(PING, {}).then(() => {}).catch(() => {
        // ping is not ok => authentication is required again.
        this.$store.dispatch('error', 'Attention, votre session a expiré');
        this.$store.dispatch(LOGOUT, {});
      });
    }
  },
};
</script>

<style lang="scss">
  .page-accueil {
    h1 { margin-bottom:4rem; }
    .container {
      @media (min-width: 768px) {
        height: 50vh;
      }

      display: flex; align-items: center; justify-items: center
    }
    .warning {margin-top:1.5rem; padding-top: 1rem; border-top:1px darkred solid}
    .presentation {
      margin-top: 4rem !important;
      min-height: 200px; text-align: center;
      font-weight: 400; font-size: 1.2rem;
    }
  }
</style>
